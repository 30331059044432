<template>
  <div>

    <div v-if="step === 'pre' " class="main-page">

      <div class="student-info">
        <img :src="student.photo" class="avatar" />
        <div class="cnname">{{ student.cnName }}</div>
      </div>
      <div class="wxuser">
        <span class="label">监护人微信</span>
        <span class="value">
          <ul>
            <li>
              <img :src="userInfo.avatar" class="mini-avatar" />
              <span class="nickname"> {{ userInfo.nickname }}</span>
            </li>
          </ul>
        </span>
      </div>
      <div class="wxuser">
        <span class="label" style="align-self: start;">已绑定微信</span>
        <span class="value">
          <ul>
            <li v-for="(item,idx) in student.boundedWxUserList" :key="idx">
              <img :src="item.avatar" class="mini-avatar" />
              <span class="nickname">{{ item.nickname }}</span>
            </li>
          </ul>
        </span>
      </div>
      <van-button type="primary" block @click="confirmBound()">确认绑定</van-button>
    </div>
    <div v-if="step === 'error'" class="main-page error">
      <ul>
        <li>绑定失败</li>
        <li>原因：【{{ errMsg }}】</li>
        <li>请向老师反馈此问题！</li>
      </ul>
    </div>
  </div>
</template>
<script>
import eduApi from '@/api/edu'

export default {
  data() {
    return {
      appId: '',
      student: {
        id: ''
      },
      step: 'pre',
      errMsg: '未知错误',
      boundedWxUserList: []
    }
  },
  computed: {
    userInfo() {
      if (process.env.NODE_ENV === 'development') {
        return {
          nickname: 'akio'
        }
      } else {
        return this.$store.getters.userInfo
      }
    }
  },
  created() {
    console.log(this.step)
    this.appId = this.$route.params.appId
    this.student.id = this.$route.params.id
    this.initData()
  },
  mounted() {},
  methods: {
    initData() {
      // 取得学员信息
      eduApi.studentDetail(this.student.id).then(res => {
        if (res.succ) {
          this.student = res.data.item
          if (!this.student.photo) {
            this.student.photo = 'http://backend.c60rock.com/img/default_avatar_2.jpg?imageView2/1/w/80/h/80'
          }
        }
      })
    },
    confirmBound() {
      this.$dialog
        .confirm({
          title: '确认绑定',
          message: '确定绑定学员信息吗？'
        })
        .then(() => {
          eduApi.studentBoundWxUser(this.student.id).then(res => {
            if (res.succ) {
              this.$router.push(`/${this.appId}/ucenter`)
            } else {
              this.step = 'error'
              this.errMsg = res.message
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    }
  }
}
</script>
<style lang="less" scoped>
.main-page {
  padding: 0.5rem;
  background-color: #fff;
  .student-info {
    text-align: center;
    margin-bottom: 0.5rem;
    img.avatar {
      border: 1px solid #ccc;
      width: 2rem;
      height: 2rem;
      margin: 0.5rem;
    }
    .cnname {
      font-size: 0.5rem;
    }
  }
  .wxuser {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 0.5rem;
    .label {
      font-size: 0.4rem;
    }
    .value {
      margin-left: 0.5rem;
      img.mini-avatar {
        border: 1px solid #ccc;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
      }
      .nickname {
        margin-left: 0.5rem;
      }
      ul {
        li {
          display: flex;
          align-items: center;
          margin-bottom: 0.2rem;
        }
      }
    }
  }
  &.error {
    ul {
      li {
        text-align: center;
        height: 1rem;
        line-height: 1rem;
        font-size: 0.4rem;
      }
    }
  }
}
</style>
